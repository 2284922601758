/*
 * Createx | Multipurpose Bootstrap Template
 * Copyright 2022 Createx Studio
 * Theme styles
*/

// User variables
@layer theme {
  @import "user-variables";

  // Configuration & utilities
  @import "variables";
  @import "mixins";
  @import "utilities";

  // Bootstrap
  @import "../vendor/bootstrap/scss/bootstrap";

  // Layout & components
  @import "reboot";
  @import "components";
}
